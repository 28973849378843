// AgentDetails.js
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import {
  Box, TextField, IconButton, Button, List, ListItem,
  ListItemText, ListItemIcon, Typography, Select, MenuItem, Checkbox,
  FormControlLabel, Collapse, useMediaQuery, RadioGroup, Radio, ListSubheader
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

import GroupIcon from '@mui/icons-material/Group';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
//import AgentPromptChain from './AgentPromptChain';
import { TextFieldStyled, DrawerPaperStyled, TitleStyled, ListItemButtonStyled, FormStyled, SubmitButtonStyled, ListItemStyled, AddAgentButtonStyled, GrayBackgroundStyled, SaveButtonContainerStyled, SaveButtonStyled, RevisionNavigationStyled } from './AgentDetailsStyles';
import { useAgentContext } from 'src/contexts/AgentContext';
import { useNavigationContext } from 'src/contexts/NavigationContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { useAuth } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import AlignmentDataAutocompleteEditor from './AlignmentDataAutocompleteEditor/AlignmentDataAutocompleteEditor'

import { useNavigate } from 'react-router-dom';
import { useQASheetContext } from 'src/contexts/QASheetContext';
import routes from 'src/config/routes';
import axios from 'axios';
import FileSaver from 'file-saver';
import { debounce, set, isNaN } from 'lodash';

import { v4 as uuid } from 'uuid';

import { hasAgentRevisionChanged } from 'src/components/utils';

function AgentDetails() {
  const {
    selectedAgent,
    setSelectedAgentRevision,
    selectedAgentRevision,
    saveAgentRevision,
    handleSelectPreviousRevision,
    handleSelectNextRevision,
    selectedAgentRevisionIndex,
    lastSavedAgentRevisions
  } = useAgentContext();
  const { openNavigationPane } = useNavigationContext();
  const { user } = useAuth();
  //console.log('user', user);

  const [isPromptChainExpanded, setIsPromptChainExpanded] = useState(false);
  const [tempAgentRevision, setTempAgentRevision] = useState({ ...selectedAgentRevision });

  const [isPublishExpanded, setPublishExpanded] = useState(false);
  const [isAdvancedSettingsExpanded, setAdvancedSettingsExpanded] = useState(false);
  const [isTestSuitesExpanded, setTestSuitesExpanded] = useState(false);

  const [isInternalSharingExpanded, setIsInternalSharingExpanded] = useState(false);
  const [internalShareEmails, setInternalShareEmails] = useState([]);
  const [sharedOrganizationIds, setSharedOrganizationIds] = useState([]);

  const [sharedUserEmails, setSharedUserEmails] = useState([]);

  const { openSnackbar } = useContext(SnackbarContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const { fetchQASheetForAgentCallback } = useQASheetContext();
  const { members } = useOrganizationContext();

  const [autocompleteComponentKey, setAutocompleteComponentKey] = useState(0);

  const fileInputRef = useRef(null);
  const [useOCR, setUseOCR] = useState(false);
  const [isAppendFileDisabled, setIsAppendFileDisabled] = useState(false);

  const togglePromptChain = () => {
    setIsPromptChainExpanded(prevState => !prevState);
  };

  // Remove the useStyles and replace class-based elements with styled components or sx props

  const maxOutputTokensPerModel = {
    "gpt-4o": 16384,
    "gpt-4o-mini": 16384,
    "gpt-4-turbo-preview": 4096,
    "gemini-1.5-pro-latest": 8192,
    "gemini-1.5-flash": 8192,
    "gemini-1.5-flash-8b": 8192,
    "claude-3-5-sonnet-latest": 8192,
    "claude-3-5-haiku-latest": 8192,
    "claude-instant-1.2": 4096,
    "gpt-3.5-turbo": 4096,
    "gpt-4": 8192,
    "open-mistral-7b": 2048,
    "open-mixtral-8x7b": 2048,
    "open-mistral-nemo": 2048,
    "mistral-small-latest": 1024,
    "mistral-medium-latest": 2048,
    "mistral-large-latest": 4096,
    "codestral-latest": 4096,
    "o1-preview": 32768,
    "o1-mini": 65536,
    "o1": 100000,
    "grok-beta": 4096,
    "grok-2-latest": 4096,
  }

  const handleSaveAgent = () => {
    if (!selectedAgent.sharedWithUser) {
      saveAgentRevision(selectedAgent, tempAgentRevision);
    }
  };

  const handleNavigateToQASheet = () => {
    fetchQASheetForAgentCallback(selectedAgent.id);
    navigate(`/agent/${selectedAgent.id}/qasheet`);
  };

  const handleOrganizationShareChange = (event, newValue) => {
    setSharedOrganizationIds(newValue.map(option => option.id));
  };

  const handleInternalShare = async () => {
    try {
      const response = await axios.post(`${routes.agents}/${selectedAgent.id}/share`, {
        sharedUserEmails: internalShareEmails,  // Send array of emails
        sharedOrganizationIds: sharedOrganizationIds,  // Send array of organization IDs
      });
      if (response.data.status === 'success') {
        openSnackbar('Agent sharing updated successfully', 'success');
      }
    } catch (error) {
      console.error('Error updating agent sharing:', error);
      openSnackbar('Failed to update agent sharing. Please try again.', 'error');
    }
  };

  const handleRatingsExportClick = () => {
    axios.get(routes.ratingsExport,
      { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data],
          { type: 'text/csv' });
        FileSaver.saveAs(blob, 'ratings.csv');
      });
  };

  const handleUpdateAgentRevisionProperty = (event, property, allowBlank = true) => {
    let value = event.target.value;
    const min = 0;
    const floatProperties = ['temperature', 'topP', 'piiThreshold'];
    const booleanProperties = ['scrubPii', 'streamResponses', 'allowPublicSharing', 'smartDocEvaluation', 'fullDocEvaluation', 'hasPhoneNumber'];

    // If value < min or value > max, set value to closest limit
    const limitValue = (value, min, max) => {
      let parsedValue = value;
      if (!parsedValue && parsedValue !== 0) {
        if (allowBlank) {
          return '';
        } else {
          parsedValue = 0;
        }
      }
      return Math.max(min, Math.min(max, parsedValue));
    };

    if (floatProperties.includes(property)) {
      value = parseFloat(value);
      const max = property === 'temperature' ? 2 : 1;
      value = limitValue(value, min, max);
    }

    if (property === 'maxTokens') {
      value = parseInt(value, 10);
      const max = maxOutputTokensPerModel[tempAgentRevision.model];
      value = limitValue(value, min, max);
    }

    if (booleanProperties.includes(property)) {
      value = event.target.checked;
    }

    if (property === 'customShareUrl') {
      value = value.replace(/[^a-zA-Z0-9-]/g, '-');
    }

    updateAgentRevisionProperty(property, value);
  };

  const updateAgentRevisionProperty = (property, value) => {
    setTempAgentRevision(prevState => ({
      ...prevState,
      [property]: value,
    }));
    debouncedUpdateSelectedAgentProperty(property, value);
  }

  const handleSelectIntelligentEngineProperty = (mode) => {
    const update = {
      smartDocEvaluation:  mode === 'smart',
      fullDocAnalysis:  mode === 'full'
    }
    setTempAgentRevision(prevState => ({
      ...prevState,
      ...update,
    }));
    setSelectedAgentRevision((prevState) => {
      return { ...tempAgentRevision, ...update }
    });
  }

  const getUniqueOrganizations = () => {
    const organizations = [];
    const organizationMap = new Map();

    if (!members) {
      return []
    }

    for (const member of members) {
      if (!organizationMap.has(member.organizationId)) {
        organizationMap.set(member.organizationId, true); // set any value to Map
        organizations.push({
          id: member.organizationId,
          name: member.organization,
        });
      }
    }

    return organizations;
  };

  const handleSelectAllMembers = (organization) => {
    const organizationId = members.find(member => member.organization === organization)?.organizationId;
    if (!organizationId) return;

    setInternalShareEmails((prevEmails) => {
      const organizationEmails = members
        .filter(member => member.organization === organization)
        .map(member => member.email);
      const allSelected = organizationEmails.every(email => prevEmails.includes(email));
      if (allSelected) {
        // Deselect all members of this organization
        return prevEmails.filter(email => !organizationEmails.includes(email));
      } else {
        // Select all members of this organization
        return [...new Set([...prevEmails, ...organizationEmails])];
      }
    });
  };

  const debouncedUpdateSelectedAgentProperty = useCallback(debounce((property, value) => {
    setSelectedAgentRevision((prevState) => {
      return { ...tempAgentRevision, [property]: value }
    });
  }, 500), [tempAgentRevision.id, selectedAgentRevision]);

  // New agent selected or persisted?
  useEffect(() => {
    setTempAgentRevision((prevState) => {
      return (tempAgentRevision.agentId === selectedAgentRevision.agentId && tempAgentRevision.id === selectedAgentRevision.id) ? tempAgentRevision : { ...selectedAgentRevision };
    });
  }, [selectedAgentRevision]);

  // For when revision # changes
  useEffect(() => {
    // Set autocomplete key to a random vaalue to force a re-render of the component
    setAutocompleteComponentKey(uuid());
  }, [selectedAgentRevision.id]);

  useEffect(() => {
    const fetchSharingInfo = async () => {
      try {
        const response = await axios.get(`${routes.agents}/${selectedAgent.id}/sharing-info`);
        setSharedUserEmails(response.data.emails);
        setInternalShareEmails(response.data.emails);  // Initialize with shared users
        setSharedOrganizationIds(response.data.organizationIds);
      } catch (error) {
        console.error('Error fetching sharing info:', error);
        // openSnackbar('Failed to fetch sharing info. Please try again.', 'error');
      }
    };

    if (selectedAgent.id) {
      fetchSharingInfo();
    }
  }, [selectedAgent.id, openSnackbar]);

  const shareUrl = !!tempAgentRevision.customShareUrl
    ? `${process.env.REACT_APP_HOST}/share/agent/${selectedAgent.id}/${tempAgentRevision.customShareUrl}`
    : `${process.env.REACT_APP_HOST}/share/agent/${selectedAgent.id}`;

  const lastSavedRevision = lastSavedAgentRevisions[tempAgentRevision.agentId] || {};

  let alignmentInstructionsPlaceholder = 'ex: Act as a Project Manager with expertise in Jira, Scrum, and Agile Methodologies.';
  alignmentInstructionsPlaceholder += !isMobile ? ' Examine provided user stories and provide a description, test criteria, sub-tasks and estimates in story points.' : '';

  const organizationOptions = getUniqueOrganizations();

  let documentAnalysisMode = 'none';
  if (selectedAgentRevision.smartDocEvaluation) {
    documentAnalysisMode = 'smart';
  } else if (selectedAgentRevision.fullDocAnalysis) {
    documentAnalysisMode = 'full'
  }

  let maxResponseTokens = maxOutputTokensPerModel[tempAgentRevision.model];

  return (
    <DrawerPaperStyled>
      {/* Save Button */}
      <SaveButtonContainerStyled>
        <SaveButtonStyled
          disabled={!hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) || selectedAgent.sharedWithUser}
          variant="contained"
          color="primary"
          onClick={handleSaveAgent}
          sx={{
            padding: '10px 30px',
          }}
        >
          Save
        </SaveButtonStyled>
      </SaveButtonContainerStyled>

      {/* Back Button */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              if (hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) && !selectedAgent.isShared) {
                handleSaveAgent();
              }
              setTimeout(() => { openNavigationPane('agentList') }, 0);
            }}
          >
            <ArrowLeftIcon />
          </IconButton>

          <Typography variant="body2" sx={{ ml: 1 }}>
            Back
          </Typography>
        </Box>


      <Typography variant="h6" sx={{
        fontWeight: 600,
        backgroundColor: 'lightgrey',
        borderBottom: '1px solid black',
        padding: '8px',
        marginBottom: '8px'
      }}>
        Agent: {tempAgentRevision.name}
      </Typography>
      {selectedAgent.sharedWithUser && (
        <ListItem sx={{ paddingLeft: 1, paddingRight: 0 }}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                <span style={{ fontWeight: 'bold' }}>Shared By: </span>
                <span>{`${selectedAgent.ownerEmail}`}</span>
              </>
            }
          />
        </ListItem>
      )}

      {/* Revision Navigation */}
      <RevisionNavigationStyled>
        <IconButton
          onClick={handleSelectPreviousRevision}
          disabled={selectedAgentRevisionIndex === 0}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="body1">
          Revision #{tempAgentRevision.id}
        </Typography>
        <IconButton
          onClick={handleSelectNextRevision}
          disabled={selectedAgentRevisionIndex === selectedAgent.revisions.length - 1}
        >
          <ArrowRightIcon />
        </IconButton>
      </RevisionNavigationStyled>

      {/* Agent Details Form */}
      <FormStyled>
        {/* Agent Name */}
        <TextFieldStyled
          label="Agent Name"
          variant="outlined"
          value={tempAgentRevision.name}
          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'name')}
          onKeyPress={(event) => event.key === 'Enter' && handleUpdateAgentRevisionProperty(event, 'name')}
          disabled={selectedAgent.sharedWithUser}
          fullWidth
        />

        {/* Model Selection */}
        <Select
          variant="outlined"
          fullWidth
          value={tempAgentRevision.model || 'gpt-4'}
          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'model')}
          sx={{ mt: 2, mb: 2 }}
          disabled={selectedAgent.sharedWithUser}
        >
          {/* Conditional Options */}
          <MenuItem value="gpt-4o">OpenAI: GPT-4o</MenuItem>
          <MenuItem value="gpt-4o-mini">OpenAI: GPT-4o Mini</MenuItem>
          <MenuItem value="gpt-4-turbo-preview">OpenAI: GPT-4 Turbo (Preview)</MenuItem>
          <MenuItem value="gemini-1.5-pro-latest">Google: Gemini Pro 1.5</MenuItem>
          <MenuItem value="gemini-1.5-flash">Google: Gemini Flash</MenuItem>
          <MenuItem value="gemini-1.5-flash-8b">Google: Gemini Flash 8B</MenuItem>
          <MenuItem value="claude-3-5-sonnet-latest">Anthropic: Claude 3.5 Sonnet</MenuItem>
          <MenuItem value="claude-3-5-haiku-latest">Anthropic: Claude 3.5 Haiku</MenuItem>
          <MenuItem value="gpt-3.5-turbo">OpenAI: GPT-3.5 Turbo</MenuItem>
          <MenuItem value="gpt-4">OpenAI: GPT-4</MenuItem>
          <MenuItem value="open-mistral-7b">Mistral: 7B</MenuItem>
          <MenuItem value="open-mixtral-8x7b">Mixtral: 8x7B</MenuItem>
          <MenuItem value="open-mistral-nemo">Mistral: Nemo</MenuItem>
          <MenuItem value="mistral-small-latest">Mistral: Small</MenuItem>
          <MenuItem value="mistral-medium-latest">Mistral: Medium</MenuItem>
          <MenuItem value="mistral-large-latest">Mistral: Large 2</MenuItem>
          <MenuItem value="codestral-latest">Mistral: Codestral</MenuItem>
          <ListSubheader>Data Collected Models</ListSubheader>
          <MenuItem value="grok-2-latest">xAI: Grok 2</MenuItem>
          <MenuItem value="grok-beta">xAI: Grok Beta</MenuItem>
          <ListSubheader>Premium (high cost) models</ListSubheader>
          <MenuItem value="o1">OpenAI: o1</MenuItem>
          <MenuItem value="o1-preview">OpenAI: o1-preview</MenuItem>
          <MenuItem value="o1-mini">OpenAI: o1-mini</MenuItem>
        </Select>

        {/* Conditional Image Dimensions */}
        {(tempAgentRevision.model === 'img-dall-e-2' || tempAgentRevision.model === 'img-dall-e-3') && (
          <Select
            variant="outlined"
            fullWidth
            value={tempAgentRevision.imageDimensions || ''}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'imageDimensions')}
            sx={{ mt: 2, mb: 2 }}
            disabled={selectedAgent.sharedWithUser}
          >
            {tempAgentRevision.model === 'img-dall-e-2' && (
              <>
                <MenuItem value="256x256">256x256</MenuItem>
                <MenuItem value="512x512">512x512</MenuItem>
                <MenuItem value="1024x1024">1024x1024</MenuItem>
              </>
            )}
            {tempAgentRevision.model === 'img-dall-e-3' && (
              <>
                <MenuItem value="1024x1024">1024x1024</MenuItem>
                <MenuItem value="1792x1024">1792x1024</MenuItem>
                <MenuItem value="1024x1792">1024x1792</MenuItem>
              </>
            )}
          </Select>
        )}

        <Box sx={{ position: 'relative', mb: 2 }}>
          {(tempAgentRevision.model === 'o1-preview' || tempAgentRevision.model === 'o1-mini' || tempAgentRevision.model === 'o1') && (
            <Typography variant="body2" color="info.main" sx={{ mb: 1 }}>
              o1 does not support system instructions, streaming, or custom temperature / top_p parameters. We will automatically convert your system instructions to a user query and respond once the LLM has fully finished processing.
            </Typography>
          )}
          {(tempAgentRevision.model === 'grok-beta' || tempAgentRevision.model === 'grok-2-latest') && (
            <Typography variant="body2" color="info.main" sx={{ mb: 1 }}>
              xAI models including grok-beta and grok-2 ("grok") may collect your data for training.
            </Typography>
          )}
          <AlignmentDataAutocompleteEditor
            key={`alignment-data-autocomplete-editor-${autocompleteComponentKey}`}
            onChange={(text) => handleUpdateAgentRevisionProperty({ target: { value: text } }, 'masterPrompt')}
            inputText={tempAgentRevision.masterPrompt}
            placeholder={alignmentInstructionsPlaceholder}
            label={'System Instructions (AI Persona)'}
            minLines={3.5}
            editable={!selectedAgent.sharedWithUser}
          />
        </Box>
        {/* Display note for specific models */}
        {[
          'claude-2.0',
          'claude-2.1',
          'claude-3-opus-20240229',
          'claude-3-sonnet-20240229',
          'claude-3-haiku-20240307',
          'claude-3.5-haiku-latest',
          'claude-3.5-sonnet-latest',
          'claude-instant-1.2',
          'gemini-1.0-pro',
          'gemini-1.5-pro-latest',
          'gemini-1.5-flash',
          'gemini-1.5-flash-8b',
          'open-mistral-7b',
          'open-mixtral-8x7b',
          'mistral-small-latest',
          'mistral-medium-latest',
          'mistral-large-latest',
          'codestral-latest'
        ].includes(tempAgentRevision.model) && (
            <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
              NOTE: Some LLMs require that the first message be from a human. We ignore assistant messages when such models are selected.
            </Typography>
          )}

        {/* Introductory Remarks */}
        <TextFieldStyled
          label="Introductory Remarks"
          variant="outlined"
          fullWidth
          placeholder={`Example: Hello, how can I assist you today?`}
          value={tempAgentRevision.introductoryText}
          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'introductoryText')}
          multiline
          minRows={3}
          maxRows={15}
          disabled={selectedAgent.sharedWithUser}
          sx={{ mb: 1, mt: 1 }}
        />

        {/* Internal Sharing Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setIsInternalSharingExpanded(!isInternalSharingExpanded)}>
            <ListItemText primary="Internal Sharing" />
            {isInternalSharingExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isInternalSharingExpanded} timeout="auto" unmountOnExit>
          <List component="div">
            {/* Share with Users */}
            <ListItem sx={{ pl: 4 }}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={members}
                groupBy={(option) => option.organization}
                getOptionLabel={(option) => option.email}
                value={members?.filter(member => internalShareEmails.includes(member.email))}
                limitTags={3}
                disabled={selectedAgent.sharedWithUser}
                onChange={(event, newValue) => {
                  setInternalShareEmails(newValue.map(option => option.email));
                }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={selected}
                      />
                      {option.email}
                    </li>
                  );
                }}
                renderGroup={(params) => {
                  return (
                    <div key={params.key}>
                      <div onClick={() => handleSelectAllMembers(params.group)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          checked={members
                            .filter(member => member.organization === params.group)
                            .every(member => internalShareEmails.includes(member.email))}
                          indeterminate={members
                            .filter(member => member.organization === params.group)
                            .some(member => internalShareEmails.includes(member.email)) && !members
                              .filter(member => member.organization === params.group)
                              .every(member => internalShareEmails.includes(member.email))}
                        />
                        <strong>{params.group}</strong>
                      </div>
                      {params.children}
                    </div>
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Share with Users (Email)"
                    placeholder="Enter user emails"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
                sx={{ width: '100%' }}
              />
            </ListItem>

            {/* Share with Organizations */}
            <ListItem sx={{ pl: 4 }}>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={organizationOptions}
                getOptionLabel={(option) => option.name}
                value={organizationOptions.filter(org => sharedOrganizationIds.includes(org.id))}
                onChange={handleOrganizationShareChange}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      checked={sharedOrganizationIds.includes(option.id)} // Check if the organization is selected
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Share with Organizations"
                    placeholder="Enter organization names"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                )}
                sx={{ width: '100%' }}
              />
            </ListItem>

            {/* Share Button */}
            <ListItem sx={{ pl: 4 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleInternalShare}
                disabled={selectedAgent.sharedWithUser}
                sx={{ mt: 2, ml: '1rem' }}
              >
                Share
              </Button>
            </ListItem>
          </List>
        </Collapse>

        {/* Advanced Settings Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setAdvancedSettingsExpanded(!isAdvancedSettingsExpanded)}>
            <ListItemText primary="Advanced Settings" />
            {isAdvancedSettingsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isAdvancedSettingsExpanded} timeout="auto" unmountOnExit>
          {/* Temperature */}
          {!tempAgentRevision?.model.startsWith('img') && (
            <>
              <TextFieldStyled
                label="Temperature (0.0 - 2.0)"
                type="number"
                variant="outlined"
                value={tempAgentRevision.temperature}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'temperature')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'temperature', false)}
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: 2,
                  step: 0.1,
                }}
              />

              {/* top_p */}
              <TextFieldStyled
                label="top_p (0.0 - 1.0)"
                type="number"
                variant="outlined"
                value={tempAgentRevision.topP}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'topP')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'topP', false)}
                fullWidth
                sx={{ mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.1,
                }}
              />

              {/* max response tokens */}
              <TextFieldStyled
                label={`Max Response Tokens (0 - ${maxResponseTokens})`}
                type="number"
                variant="outlined"
                value={tempAgentRevision.maxTokens}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'maxTokens')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'maxTokens', false)}
                fullWidth
                sx={{ mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: maxResponseTokens,
                }}
              />

              {/* Scrub PII */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempAgentRevision.scrubPii}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'scrubPii')}
                    disabled={selectedAgent.sharedWithUser}
                  />
                }
                label="Scrub PII"
              />
              <Typography variant="body2" sx={{ mb: 2 }}>
                Between 0.0 - 1.0. Lower is more sensitive.
              </Typography>
              <TextFieldStyled
                label="PII Threshold"
                type="number"
                variant="outlined"
                value={tempAgentRevision.piiThreshold}
                onChange={(event) => handleUpdateAgentRevisionProperty(event, 'piiThreshold')}
                onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'piiThreshold', false)}
                fullWidth
                sx={{ mb: 2 }}
                disabled={selectedAgent.sharedWithUser}
                inputProps={{
                  min: 0,
                  max: 1,
                  step: 0.01,
                }}
              />

              {/* Document Analysis */}
              <Typography variant="body2" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
                Intelligent Engines
                <br /><br />
                Smart: Better, faster results for large document or Data Vault queries, at 1/100th the cost.
                <br /><br />
                Exhaustive: Send the ENTIRE document (up to the Max Document Length, default 5,000,000) to the LLM, no matter its size.
              </Typography>
              <RadioGroup
                value={documentAnalysisMode}
                onChange={(event) => {
                  const mode = event.target.value;
                  handleSelectIntelligentEngineProperty(mode);
                }}
                row
              >
                <FormControlLabel
                  value="none"
                  control={<Radio />}
                  label="None"
                  disabled={selectedAgent.sharedWithUser}
                />
                <FormControlLabel
                  value="smart"
                  control={<Radio />}
                  label="Smart Document Evaluation"
                  disabled={selectedAgent.sharedWithUser}
                />
                <FormControlLabel
                  value="full"
                  control={<Radio />}
                  label="Exhaustive Search"
                  disabled={selectedAgent.sharedWithUser}
                />
              </RadioGroup>

              {/* Conditional Fields for Full Document Analysis */}
              {documentAnalysisMode === 'full' && (
                <>
                  <TextFieldStyled
                    label="Chunk Size"
                    type="number"
                    variant="outlined"
                    value={tempAgentRevision.fullDocChunkSize || ''}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'fullDocChunkSize')}
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    disabled={selectedAgent.sharedWithUser}
                  />

                  <TextFieldStyled
                    label="Chunk Overlap"
                    type="number"
                    variant="outlined"
                    value={tempAgentRevision.fullDocChunkOverlap || ''}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'fullDocChunkOverlap')}
                    fullWidth
                    sx={{ mb: 2 }}
                    disabled={selectedAgent.sharedWithUser}
                  />

                  <TextFieldStyled
                    label="Max Document Length (in characters)"
                    type="number"
                    variant="outlined"
                    value={tempAgentRevision.fullDocMaxLength || ''}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'fullDocMaxLength')}
                    fullWidth
                    sx={{ mb: 2 }}
                    disabled={selectedAgent.sharedWithUser}
                  />
                </>
              )}

              {/* Streaming */}
              <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                Streaming is the preferred way to use the platform as a user. It's recommended to keep this enabled.
              </Typography>
              {(tempAgentRevision.model === 'o1-preview' || tempAgentRevision.model === 'o1-mini' || tempAgentRevision.model === 'o1') && (
                <Typography variant="body2" color="error" sx={{ mb: 2 }}>
                  o1 does not support streaming. Your responses will be returned in full once processing is complete.
                </Typography>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempAgentRevision.streamResponses}
                    onChange={(event) => {
                      handleUpdateAgentRevisionProperty(event, 'streamResponses')
                    }}
                    disabled={selectedAgent.sharedWithUser}
                  />
                }
                label="Stream Responses (if supported)"
              />
            </>
          )}
        </Collapse>

        {/* Test Suites Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setTestSuitesExpanded(!isTestSuitesExpanded)}>
            <ListItemText primary="Test Suites" />
            {isTestSuitesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isTestSuitesExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button className={classes.nested} onClick={() => { return; }}>
              <ListItemText primary="Q&A Sheet" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Test Runs (Coming Soon)" />
            </ListItem> */}
            <ListItem button sx={{ pl: 4 }} disabled={selectedAgent.sharedWithUser} onClick={handleRatingsExportClick}>
              <ListItemText primary="Ratings Export" />
            </ListItem>
          </List>
        </Collapse>

        {/* Publish Section */}
        <ListItemStyled>
          <ListItemButtonStyled onClick={() => setPublishExpanded(!isPublishExpanded)}>
            <ListItemText primary="Publish" />
            {isPublishExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButtonStyled>
        </ListItemStyled>
        <Collapse in={isPublishExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempAgentRevision.allowPublicSharing}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'allowPublicSharing')}
                    disabled={selectedAgent.sharedWithUser}
                  />
                }
                label="Public Share Link?"
              />
              {tempAgentRevision.allowPublicSharing && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Link will not be accessible until you "Save" the agent. WARNING: Saving with this option disabled will DEACTIVATE all of your publicly shared links.
                  </Typography>
                  <Typography variant="body2" component="a" href={shareUrl} sx={{ display: 'block', mb: 1, color: 'primary.main' }}>
                    {shareUrl}
                  </Typography>
                  {/* Custom URL input box */}
                  <TextFieldStyled
                    label="Custom Share Page URL"
                    placeholder='ex: my-agent-name'
                    variant="outlined"
                    fullWidth
                    value={tempAgentRevision.customShareUrl || ''}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'customShareUrl')}
                    sx={{ mb: 2 }}
                    disabled={selectedAgent.sharedWithUser}
                  />
                  {/* Button to copy link into clipboard */}
                  <Button
                    variant="contained" color="primary" size="small" onClick={() => {
                      openSnackbar('Link copied to clipboard');
                      navigator.clipboard.writeText(shareUrl);
                    }}
                    disabled={selectedAgent.sharedWithUser}
                  >
                    Copy
                  </Button>
                </Box>
              )}
            </ListItem>

            {/* <ListItem button className={classes.nested}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tempAgentRevision.hasPhoneNumber}
                    onChange={(event) => handleUpdateAgentRevisionProperty(event, 'hasPhoneNumber')}
                  />
                }
                label="Has Phone Number?"
              />
              {tempAgentRevision.hasPhoneNumber && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Save for SMS. A2P 10DLC guidelines apply.
                  </Typography>
                  <TextFieldStyled
                    label="Phone number"
                    placeholder='Save to View'
                    variant="filled"
                    fullWidth
                    value={tempAgentRevision.phoneNumber || ''}
                    disabled
                    sx={{ mb: 2 }}
                  />
                </Box>
              )}
            </ListItem> */}
          </List>
        </Collapse>
      </FormStyled>

      {/* Save Button at the Bottom */}
      <SaveButtonContainerStyled>
        <SaveButtonStyled
          disabled={!hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) || selectedAgent.sharedWithUser}
          variant="contained"
          color="primary"
          onClick={handleSaveAgent}
          sx={{
            padding: '10px 30px',
          }}
        >
          Save
        </SaveButtonStyled>
      </SaveButtonContainerStyled>
    </DrawerPaperStyled>
  );
}

export default AgentDetails;