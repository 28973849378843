// AgentList.js
import { useState, useMemo, useEffect, useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import {
  List,
  ListItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  InputBase,
  Box
} from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from '@mui/icons-material/Group';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import { useAgentContext } from "src/contexts/AgentContext";
import { useNavigationContext } from "src/contexts/NavigationContext";
import { useWorkflowContext } from 'src/contexts/WorkflowContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import useLocalStorage from 'src/hooks/useLocalStorage';

export default function AgentList() {
  const {
    agents, addAgent, deleteAgent, defaultAgent, setSelectedAgent, setSelectedAgentRevision, setSelectedAgentRevisionIndex, saveAgentRevision, lastSavedAgentRevisions, setLastSavedAgentRevisions
  } = useAgentContext();
  const {
    setSelectedWorkflow, setSelectedWorkflowRevision
  } = useWorkflowContext();
  const { openNavigationPane } = useNavigationContext();
  const { selectedOrganization } = useOrganizationContext();
  const [recentAgentIds, setRecentAgentIds] = useLocalStorage('recentAgentIds', []);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSharedAgents, setShowSharedAgents] = useState(false);
  
  const sortedAgents = useMemo(() => {
    return [...agents].sort((a, b) => {
      const aIndex = recentAgentIds.indexOf(a.id);
      const bIndex = recentAgentIds.indexOf(b.id);
      
      if (aIndex === -1 && bIndex === -1) {
        return agents.indexOf(a) - agents.indexOf(b);
      }
      
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      
      return aIndex - bIndex;
    });
  }, [agents, recentAgentIds]);

  useEffect(() => {
    if (agents.length > 0) {
      // Find new agents that are not in recentAgentIds
      const newAgentIds = agents
        .map(agent => agent.id)
        .filter(id => !recentAgentIds.includes(id));
  
      if (newAgentIds.length > 0) {
        // Update recentAgentIds with new agent IDs
        setRecentAgentIds([...newAgentIds, ...recentAgentIds]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents, recentAgentIds]);
  
  const filterAgentsBySearchAndSharing = (agentsList) => {
    const filterAgent = (agent) => {
      const latestRevision = agent.revisions[agent.revisions.length - 1];
      const nameMatches = latestRevision?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const sharingMatches = !showSharedAgents || agent.sharedWithUser || agent.sharedByUser;
      
      return nameMatches && sharingMatches;
    };

    return agentsList.filter(filterAgent);
  };

  // Open the confirmation dialog and store the agent ID
  const handleOpenDialog = (agentId) => {
    setAgentToDelete(agentId);
    setDialogOpen(true);
  };

  // Close the confirmation dialog and reset the agent ID
  const handleCloseDialog = () => {
    setAgentToDelete(null);
    setDialogOpen(false);
  };

  // Delete the agent and close the dialog
  const handleConfirmDelete = () => {
    if (agentToDelete) {
      deleteAgent(agentToDelete);
    }
    handleCloseDialog();
  };

  // Function to handle the search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Function to handle the shared agents filter toggle
  const handleToggleSharedAgents = () => {
    setShowSharedAgents(!showSharedAgents);
  };

  const toggleSearch = () => {
    if (isSearchOpen) {
      setSearchTerm(''); // Clear search term when closing the search box
    }
    setSearchOpen(!isSearchOpen);
  };

  const handleAddNewAgent = () => {
    const newAgent = { ...defaultAgent, id: uuid() }
    addAgent(newAgent);
    handleSelectAgent(newAgent);

    saveAgentRevision(newAgent, { ...newAgent.revisions[0], organizationId: selectedOrganization });
  };

  const handleSelectAgent = (agent) => {
    setSelectedWorkflow(null);
    setSelectedWorkflowRevision(null);
    setRecentAgentIds([agent.id, ...recentAgentIds.filter(id => id !== agent.id)]);
    setSelectedAgent(agent)
    setSelectedAgentRevision(agent.revisions[agent.revisions.length - 1])
    setLastSavedAgentRevisions({ ...lastSavedAgentRevisions, [agent.id]: agent.revisions[agent.revisions.length - 1] })
    setSelectedAgentRevisionIndex(agent.revisions.length - 1)
    openNavigationPane('agentDetails')
  };

  const filteredAgents = useMemo(() => {
    return (searchTerm || showSharedAgents) ? filterAgentsBySearchAndSharing(agents) : sortedAgents;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, agents, sortedAgents, showSharedAgents]);

  if (!agents.length) {
    return (
      <Box sx={{ padding: '1rem' }}>
        <Typography variant="body1" sx={{ padding: '1rem' }}>
          Loading agents. Click the <AddIcon /> button below to add a new agent.
        </Typography>
        <IconButton
          color="default"
          onClick={handleAddNewAgent}
          sx={{
            width: '0.6in',
            height: '0.6in',
            '& svg': {
              fontSize: '0.5in',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            },
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '535px', maxWidth: '100%' }}>
      {/* Top Action Buttons */}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          {/* Add Agent Button */}
          <IconButton
            color="default"
            onClick={handleAddNewAgent}
            sx={{
              width: '0.6in',
              height: '0.6in',
              '& svg': {
                fontSize: '0.5in',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              },
            }}
          >
            <AddIcon />
          </IconButton>

          {/* Search Toggle Button */}
          <IconButton
            color="default"
            onClick={toggleSearch}
            sx={{
              width: '0.6in',
              height: '0.6in',
              '& svg': {
                fontSize: '0.5in',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              },
            }}
          >
            <SearchIcon />
          </IconButton>

          {/* Search Input */}
          {isSearchOpen && (
            <InputBase
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              autoFocus
              sx={{
                marginLeft: 1,
                flex: 1,
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '4px 8px',
              }}
            />
          )}
        </Box>

        {/* Toggle Shared Agents */}
        <IconButton
          color={showSharedAgents ? "primary" : "default"}
          onClick={handleToggleSharedAgents}
          sx={{
            width: '0.6in',
            height: '0.6in',
            '& svg': {
              fontSize: '0.5in',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            },
            marginRight: '4%',
          }}
        >
          <GroupIcon />
        </IconButton>
      </Box>

      {/* Agents List */}
      <List>
        {filteredAgents.map((agent) => (
          <ListItem
            button
            key={agent.id}
            onClick={() => { handleSelectAgent(agent) }}
          >
            <ListItemText
              primary={agent.revisions[agent.revisions.length - 1].name}
            />
            {agent.sharedByUser ? (
              <GroupAddIcon titleAccess={'Shared With Other Users'} sx={{ color: 'rgb(99, 115, 129)' }} />
            ) : null}

            <ListItemIcon>
              {agent.sharedWithUser ? (
                <GroupIcon titleAccess={'Shared By Another User'} sx={{ marginLeft: 1 }} />
              ) : (
                <IconButton
                  disabled={agent.sharedWithUser}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&.Mui-disabled': {
                      color: 'inherit', // Set the desired color for the disabled state
                    },
                  }}
                  edge="end"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDialog(agent.id);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>

      {/* Confirmation Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Agent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this agent?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}