// Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAgentContext } from '../contexts/AgentContext';
import { useNavigationContext } from '../contexts/NavigationContext';
import { useAuth } from '../contexts/AuthContext';
import routes from 'src/config/routes';
import axios from 'axios';
import { useWorkflowContext } from 'src/contexts/WorkflowContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { isString } from 'lodash';

// -------------------------------------------------------------------------

// Styled AppBar using the `styled` API
const AppBarStyled = styled(AppBar)(({ theme }) => ({
  background: theme.palette.grey[700],
  minHeight: 40,
}));

// Styled IconButton for the drawer toggle
const DrawerIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

// Container for right-aligned items in the toolbar
const RightAlignedContainer = styled(Box)({
  marginLeft: 'auto', // Push the container to the right
  display: 'flex',
  alignItems: 'center',
});

// Styled Typography for conditional display
const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'monospace',
  backgroundColor: 'yellow',
  color: 'rgb(69, 79, 91)',
  outline: '5px solid yellow',
  outlineTop: '10px solid yellow',
  marginLeft: theme.spacing(5.625), // Equivalent to 45px
}));

// -------------------------------------------------------------------------

function Navbar() {
  const location = useLocation();
  const { selectedAgent, setAgents, setPersonalAgents, setOrganizationAgents, selectedAgentRevision, refreshAgentsFromServer } = useAgentContext();
  const { selectedWorkflow, selectedWorkflowRevision } = useWorkflowContext();
  const { setIsSideDrawerVisible, isSideDrawerVisible, openNavigationPane } = useNavigationContext();
  const { selectedOrganization, setSelectedOrganization } = useOrganizationContext();
  const navigate = useNavigate();
  const { logout, user, isLoggedIn } = useAuth();
  const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(null);

  useEffect(() => {
    if (user && user.organizationsRoles && user.organizationsRoles.length > 0) {
      setSelectedOrganization(user.defaultOrganization.id); // Set the default organization as selected
    }
  }, [user, setSelectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && isString(selectedOrganization) && isLoggedIn) {
      refreshAgentsFromServer();
    }
  }, [selectedOrganization, isLoggedIn]);

  const handleProjectDrawerToggle = () => {
    setIsSideDrawerVisible(!isSideDrawerVisible);
  };

  // When the organization is changed
  const handleOrganizationChange = async (event) => {
    setSelectedOrganization(event.target.value);
  };

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserMenuAnchorElement(null);
  };

  const handleLogout = () => {
    logout();
    handleCloseUserMenu(); // Close the menu
  };

  const handleClickBilling = () => {
    navigate('/billing'); // Navigate to the billing page
    handleCloseUserMenu(); // Close the menu
  };

  // Hide Navbar for specific routes if needed
  // Example:
  // if (location.pathname.includes('/share')) {
  //   return null; // Don't render the navbar for /share links
  // }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBarStyled position="fixed" sx={{ paddingLeft: isSideDrawerVisible ? '650px' : 'initial', backgroundColor: '#2e2c2f' }}>
        <Toolbar sx={{ display: 'flex' }}>
          {isLoggedIn && (
            <DrawerIconButton
              edge="start"
              aria-label="menu"
              onClick={handleProjectDrawerToggle}
            >
              <MenuIcon />
            </DrawerIconButton>
          )}

          {(!selectedAgentRevision && !selectedWorkflowRevision) && (
            <TitleTypography variant="h6">
              No Agent Selected
            </TitleTypography>
          )}

          {(selectedAgentRevision || selectedWorkflowRevision) && (
            <TitleTypography variant="h6">
              {selectedWorkflow ? `${selectedWorkflowRevision?.name || 'New Workflow'}` : ''}
              {selectedWorkflow && selectedAgent && ` - `}
              {`${selectedAgentRevision?.name || 'New Agent'}`}
            </TitleTypography>
          )}

          {isLoggedIn && (
            <RightAlignedContainer>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleUserMenuClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={userMenuAnchorElement}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(userMenuAnchorElement)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleClickBilling}>
                  {user.email}
                </MenuItem>
                <MenuItem>
                  <Select
                    value={selectedOrganization}
                    onChange={handleOrganizationChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ width: '100%' }} // Ensure the Select takes full width within the MenuItem
                  >
                    {user && user.organizationsRoles && user.organizationsRoles.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </MenuItem>
                <MenuItem onClick={handleClickBilling}>Usage</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </RightAlignedContainer>
          )}
        </Toolbar>
      </AppBarStyled>
    </Box>
  );
}

export default Navbar;