// AgentDetailsStyles.js
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ListItemText, ListItemButton, Button } from '@mui/material';

// Styled TextField
export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: 4,
}));

// Styled components for other elements
export const DrawerPaperStyled = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  width: theme.spacing(67),
  maxWidth: '100%'
}));

export const TitleStyled = styled('h6')(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: 'lightgrey',
  borderBottom: '1px solid black',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const FormStyled = styled('form')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const SubmitButtonStyled = styled('button')(({ theme }) => ({
  marginTop: 'auto',
  background: theme.palette.success.dark,
  '&:hover': {
    background: theme.palette.success.darker,
  },
}));

export const ListItemStyled = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  backgroundColor: theme.palette.grey[300],
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  }
}));

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(0),
}));

export const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  marginTop: 4,
  marginBottom: 4
}));

export const AddAgentButtonStyled = styled('button')(({ theme }) => ({
  width: '.6in',
  height: '.6in',
  '& svg': {
    fontSize: '0.5in',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const GrayBackgroundStyled = styled('div')({
  backgroundColor: 'lightgrey',
});

export const SaveButtonContainerStyled = styled('div')({
  marginTop: '.5rem',
  marginBottom: '.5rem',
  textAlign: 'center',
});

export const SaveButtonStyled = styled(Button)({
  padding: '10px 30px',
});

export const RevisionNavigationStyled = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px', // Gap between items, can adjust based on preference
});