// reset-password.js
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import routes from 'src/config/routes';
import SnackbarContext from 'src/contexts/SnackbarContext';

// -------------------------------------------------------------------------

// Styled Components
const FormContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(12.5), // 100px / 8 = 12.5
  padding: theme.spacing(2.5), // 20px / 8 = 2.5
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(2.5), // 20px / 8 = 2.5
}));

// -------------------------------------------------------------------------

// ResetPassword Component
const ResetPassword = () => {
  const { resetCode } = useParams(); // Reading the 'resetCode' parameter from the URL's path
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const { openSnackbar } = useContext(SnackbarContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      openSnackbar("Passwords do not match.", 'error'); // Provide immediate feedback
      return;
    }

    try {
      // Adjust the endpoint as necessary
      const response = await axios.post(routes.resetPassword, {
        newPassword: password,
        resetCode, // Use the resetCode from the URL's path
      });

      console.log('Password reset successful:', response.data);
      openSnackbar('Your password has been reset successfully.', 'success');

      navigate('/login');
    } catch (error) {
      console.error('Password reset failed:', error);
      const message = error.response?.data?.error || 'An error occurred. Please try again.';
      setErrorMessage(message);
      openSnackbar('Failed to reset password. Please try again.', 'error');
    }
  };

  return (
    <Container maxWidth="sm">
      <FormContainer elevation={3}>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Typography variant="h5" gutterBottom>
            Reset Your Password
          </Typography>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{ minLength: 6 }} // Example validation
          />
          <TextField
            label="Confirm New Password"
            type="password"
            fullWidth
            margin="normal"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            inputProps={{ minLength: 6 }}
          />
          {errorMessage && (
            <ErrorMessage variant="body2">
              {errorMessage}
            </ErrorMessage>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ marginTop: 3 }}
          >
            Reset Password
          </Button>
        </Box>
      </FormContainer>
    </Container>
  );
};

export default ResetPassword;